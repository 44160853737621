@import './mixins';

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier-Circle-regular/basiercircle-regular-webfont.eot');
  src:
    url('../fonts/Basier-Circle-regular/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Basier-Circle-regular/basiercircle-regular-webfont.woff') format('woff'),
    url('../fonts/Basier-Circle-regular/basiercircle-regular-webfont.woff2') format('woff2'),
    url('../fonts/Basier-Circle-regular/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier-Circle-medium/basiercircle-medium-webfont.eot');
  src:
    url('../fonts/Basier-Circle-medium/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Basier-Circle-medium/basiercircle-medium-webfont.woff') format('woff'),
    url('../fonts/Basier-Circle-medium/basiercircle-medium-webfont.woff2') format('woff2'),
    url('../fonts/Basier-Circle-medium/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier-Circle-bold/basiercircle-bold-webfont.eot');
  src:
    url('../fonts/Basier-Circle-bold/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Basier-Circle-bold/basiercircle-bold-webfont.woff') format('woff'),
    url('../fonts/Basier-Circle-bold/basiercircle-bold-webfont.woff2') format('woff2'),
    url('../fonts/Basier-Circle-bold/basiercircle-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  background-color: #f2f5f6;
  font-family: 'Basier Circle';
}

.ant-layout {
  display: flex;
  // grid-template-rows: 5rem calc(98vh - 10rem) 5rem;
  justify-content: center;
  font-family: 'Basier Circle';
  width: 100%;

  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    width: 100%;

    .left {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 48px;
      img {
        width: 3.75rem;
        height: 1.5rem;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 32px;
      a {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #31353b;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      background: #f2f5f6;
      height: 3rem;
      border-radius: 1.5rem;
      button {
        height: 3rem;
        border-radius: 1.5rem;
        padding: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 0;
        text-align: left;
        display: flex;
        align-items: center;
        svg {
          width: 1.25rem;
          height: 1.25rem;
          path {
            fill: #0c0d0d;
          }
        }
      }
      .inactive {
        background: transparent;
        border: 1px solid transparent;
        box-shadow: none;
        color: #979797;
        path {
          fill: #979797;
        }
      }
    }
    .profile-dropdown-title {
      display: flex;
      font-weight: 500;
      cursor: pointer;
      .up_icon {
        display: none;
      }
      &.ant-dropdown-open {
        .down_icon {
          display: none;
        }
        .up_icon {
          display: inherit;
        }
      }
    }
  }
  .content {
    padding: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    .media-max(1536px, {
      padding: 30px;
    });
    .admin {
      width: 400px;
      display: grid;
      gap: 12px;
      grid-template-columns: 150px 250px;
      align-items: center;
    }
  }
  .ant-layout-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Basier Circle';
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 10px;
    .media-max(1366px, {
      padding: 3px 10px!important;
    });
  }
  .replay-button {
    position: absolute;
    top: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    min-height: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    opacity: 0;
    border-radius: 50%;
    &-visible {
      opacity: 1;
      display: flex;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.profile-username-email {
  p {
    font-weight: 500;
    margin: 0;
  }
}

.profile-menu-minutes-wrapper {
  margin-top: 10px;
  margin-bottom: 5px;
  min-width: 220px;
  .minutes-info {
    p {
      display: flex;
      justify-content: space-between;
    }
  }
}

.ant-dropdown ul.profile-dropdown-menu {
  min-width: 200px;
  .ant-dropdown-menu-item {
    margin: 8px 0;
    &:nth-child(1) {
      pointer-events: none;
    }
    &:nth-child(3) {
      pointer-events: none;
      a {
        pointer-events: auto !important;
        color: rgba(72, 104, 255, 1);
      }
    }
  }
}

.primary-color-highlight {
  color: #4868ff;
}

.auth-modal {
  margin-bottom: 100px;
  .ant-modal {
    width: 600px !important;
    .ant-modal-content {
      display: flex;
      justify-content: center;
      padding: 0;
      .ant-modal-body {
        width: 350px;
        margin: 48px auto;
        .title {
          font-size: 48px;
          font-weight: 700;
          line-height: 57.6px;
          text-align: left;
          color: rgba(49, 53, 59, 1);
          margin: 0 auto 48px auto;
        }
        .button-signup {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0;
          margin: 48px auto 24px auto;
          padding-top: 16px;
          padding-bottom: 16px;
          height: auto;
        }
        .footer {
          font-family: Basier Circle;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0;
          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.minutes-modal {
  margin-bottom: 150px;
  .ant-modal {
    width: 400px !important;
    .ant-modal-content {
      display: flex;
      justify-content: center;
      padding: 0;
      .ant-modal-body {
        width: 320px;
        margin: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .title {
          font-family: Basier Circle;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0;
          text-align: left;
          color: rgba(49, 53, 59, 1);
          margin: 0 auto;
        }
        .subtitle {
          font-family: Basier Circle;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #31353b;
          margin: 16px auto 24px 0;
        }
        .minutes-wrapper {
          display: flex;
          align-items: flex-end;
          width: 100%;
          background-color: #f2f5f6;
          border-radius: 0.625rem;
          padding: 40px 0;
          .minutes-info {
            width: 100%;
            margin: 0 24px;
            p {
              display: flex;
              justify-content: space-between;
            }
            .ant-progress {
              margin-top: 12px;
            }
          }
        }
        .button-signup {
          font-family: Basier Circle;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0;
          margin: 24px 0 0 auto;
          padding: 8px 24px;
          gap: 8px;
          height: auto;
        }
      }
    }
  }
}

.chat-app-modal {
  .ant-modal {
    width: 400px !important;
    .ant-modal-title {
      font-family: Basier Circle;
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0;
      text-align: left;
    }
    .ant-modal-body {
      p {
        font-family: Basier Circle;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }
    }
    .ant-modal-footer {
      button.warning {
        background: rgba(207, 93, 96, 1);
      }
    }
  }
}

//@media (max-width: 1290px) {
//  html {
//    font-size: 1.1vw;
//    .ant-layout {
//      .ant-layout-footer {
//        margin-top: 0;
//      }
//    }
//  }
//}
//
//@media (max-height: 900px) {
//  html {
//    font-size: 1.1vw;
//    .ant-layout {
//      .ant-layout-footer {
//        margin-top: 0;
//        height: 3rem;
//        margin-bottom: 1rem;
//      }
//    }
//  }
//}
//
//@media (max-width: 1600px) and (min-width: 1290px) {
//  html {
//    font-size: 0.9vw;
//  }
//}
//
//@media (max-width: 900px) and (orientation: portrait) {
//  html {
//    font-size: 1.7vw;
//  }
//}
//
//@media (max-width: 1200px) and (orientation: portrait) {
//  html {
//    font-size: 2.3vw;
//  }
//}
//
//@media (max-width: 1200px) and (orientation: portrait) {
//  .ant-layout {
//    .content {
//      margin: 15px;
//    }
//    .ant-layout-footer {
//      font-size: 1.3rem;
//      line-height: 1.4rem;
//      margin-bottom: 4.4rem;
//    }
//  }
//}
//
//@media (max-width: 420px) and (orientation: portrait) {
//  .ant-layout {
//    .ant-layout-header {
//      .right {
//        gap: 10px;
//        .button-wrapper {
//          .ant-btn {
//            span {
//              margin-right: 5px;
//            }
//          }
//        }
//      }
//    }
//  }
//}
//
//@media (max-height: 420px) and (max-width: 920px) and (orientation: landscape) {
//  html {
//    position: fixed;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    height: 100%;
//    font-size: 0.5rem;
//    .ant-layout {
//      .ant-layout-content {
//        margin: 1rem 10%;
//      }
//      .ant-layout-footer {
//        margin-bottom: 0.1rem;
//        margin-top: 0;
//        padding: 0.5rem;
//        height: auto;
//      }
//    }
//  }
//}
//
//@media (max-width: 350px) and (orientation: portrait) {
//  html {
//    font-size: 2vw;
//  }
//}

