@import './mixins';

.chat {
  display: flex;
  gap: 5%;
  background-color: white;
  border-radius: 20px;
  height: 100%;
  padding: 5rem;
  overflow: hidden;
  .media-max(1536px, {
    padding: 4rem;
    margin: 0;
  });
  .media-max(1366px, {
    padding: 3rem 6rem;
  });
  &-session-inactive {
    .chat-right {
      position: relative;
      .video-wrapper {
        video {
          background: url('../avatar.png') right no-repeat;
        }
      }
    }
  }
  &-session-active {
    .video-wrapper {
      video {
        background: none;
      }
    }
  }

  .chat-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 47%;
    &-session-inactive {
      opacity: 1;
      transform: translateX(0%);
      transition: 500ms;
    }
    &-session-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: 500ms;
    }
    .intro {
      img.logo {
        width: 42%;
        //height: 72px;
        margin-bottom: 36px;
        .media-max(1366px, {
          margin-bottom: 24px;
        });
      }
      .title {
        font-size: 2.8rem;
        font-weight: 700;
        text-align: left;
        .media-max(1366px, {
          font-size: 1.8rem!important;
        });
        .media-max(1536px, {
          font-size: 2.5rem;
        });
      }
      ul {
        font-family: Basier Circle;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 30px;
        li::marker {
          color: rgba(72, 104, 255, 1);
        }
      }
    }
    .start-chat-button-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      .description {
        color: #919191;
      }
      .start-chat-button {
        margin-top: 1rem;
        padding: 10px 48px;
        height: auto;
        gap: 16px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        .media-max(1366px, {
          padding: 10px 36px;
        });
      }
    }
  }
  .chat-right {
    width: 53%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    &-session-inactive {
      transform: translateX(0%);
      transition: 500ms;
    }
    &-session-active {
      transform: translateX(-49.5%);
      transition: 500ms;
    }
    .video-wrapper {
      position: relative;
      height: 512px;
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        // border-style: solid;
        border-color: rgba(49, 53, 59, 1);
        height: 512px;
        width: 512px;
        border-radius: 20px;
        // border-width: 2px;
      }
      .record-audio-button {
        width: 78px;
        height: 78px;
        background: rgba(72, 104, 255, 1);
        position: absolute;
        z-index: 3;
        bottom: 20px;
        margin: auto;
        svg {
          width: 30px;
          height: 30px;
        }
        &:disabled {
          background: #a4a4a4;
        }
        &:disabled ~ .record-audio-button-border {
          background: linear-gradient(#919191, rgba(72, 104, 255, 0));
        }
      }
      .record-audio-button-border {
        background: linear-gradient(rgba(72, 104, 255, 1), rgba(72, 104, 255, 0));
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        bottom: 16px;
        padding: 5px;
        margin: auto;
        width: 86px;
        height: 86px;
        animation: none;
        &-sending {
          animation: rotation 1s linear infinite;
          background: linear-gradient(rgb(210, 210, 210), rgba(72, 104, 255, 0));
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// TODO possibly remove
//.reply {
//  display: flex;
//  text-align: left;
//  font-weight: 500;
//  letter-spacing: 0;
//  .user-wrapper {
//    .author {
//      float: right;
//    }
//  }
//  .empty {
//    flex: 1;
//    min-width: 40%;
//  }
//  .author {
//    color: #979797;
//    font-size: 0.88rem;
//    line-height: 14px;
//  }
//  .user,
//  .assistant {
//    position: relative;
//    border-radius: 0.625rem;
//    padding: 0.63rem 1rem;
//    font-size: 1rem;
//    line-height: 1.5rem;
//    margin: 1rem auto 0.5rem auto;
//    width: auto;
//    flex: 0;
//  }
//  .user {
//    background-color: #4868ff;
//    color: #ffffff;
//  }
//  .assistant {
//    background-color: #f2f5f6;
//  }
//  .polygon {
//    width: 18px;
//    height: 22px;
//    position: absolute;
//    clip-path: path(
//            'M5.50454 2.30684C6.04222 0.578577 8.41607 0.396499 9.21102 2.02255L16.5928 17.1216C17.2424 18.4503 16.275 20 14.796 20H2.71678C1.36856 20 0.406552 18.6932 0.807064 17.4059L5.50454 2.30684Z'
//    );
//  }
//  .polygon-user {
//    background-color: #4868ff;
//    bottom: -2px;
//    right: -5px;
//  }
//  .polygon-assistant {
//    background-color: #f2f5f6;
//    bottom: -2px;
//    left: -5px;
//  }
//}

@media (max-height: 768px) {
  .chat {
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .chat {
  }
}

